<template>
    <AppHeader @changeLanguage="changeLanguage" :language="language" />
    <information-section :language="language" id="information" />
    <use-section id="connect" :language="language" />
    <bins-section :language="language" id="bins" />
    <contact-us-section :language="language" id="call-back" />
    <app-footer :language="language" />
    <cookie-modal :cookie="cookie" :language="language"></cookie-modal>
</template>

<script lang="ts">

import { defineComponent } from 'vue';
import AppHeader from "@/components/AppHeader.vue";
import InformationSection from "@/components/InformationSection.vue";
import UseSection from "@/components/UseSection.vue";
import BinsSection from "@/components/BinsSection.vue";
import ContactUsSection from "@/components/ContactUsSection.vue";
import AppFooter from "@/components/AppFooter.vue";
import CookieModal from "@/components/CookieModal.vue";

export default defineComponent ({
  name: 'MainPage',
  data() {
    return {
      language: 'EN',
      cookie: false
    }
  },
  components: {
    CookieModal,
    AppFooter,
    ContactUsSection,
    UseSection,
    InformationSection,
    AppHeader,
    BinsSection,
  },
  methods: {
    changeLanguage(language: string) {
      this.language = language;
    },
    changeVisited() {
      localStorage.setItem('visited', 'true')
    },
    async postRef() {
      const nameRef = window.location.href.split('=').splice(1, 1)
      try {
        const response = await fetch('https://chocopay.io/api/v1/referral/transition', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            referral_name: nameRef[0]
          })
        });
      } catch (e) {
        console.error(e)
      }
    }
  },
  mounted() {
    //Проверяем, не заходил ли пользователь на сайт
    if (!localStorage.getItem('visited')) {
      //Если он перешел по базовому урлу, то не отправляем post запрос, иначе отправляем имя рефералки
      if (window.location.href.includes('ref')) {
        const refForRegister = window.location.href.split('=').splice(1, 1)[0];
        document.cookie = `referral_name=${refForRegister};path=/;domain=chocopay.io`;
        this.postRef()
      }
      this.changeVisited()
    }
    if (document.cookie.includes('RU')) {
      this.language = 'RU'
    }
    if (document.cookie.includes('cookie=true')) {
      this.cookie = true
    }
  }
})

</script>

<style>

</style>