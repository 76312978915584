<template>
  <section class="information-section">
    <div class="information">
      <h1>{{language === "EN" ? "We've only just begun but we already have" : 'Мы только начали, но у нас уже есть'}}</h1>
      <ul>
        <li>{{language === "EN" ? "integration API" : 'API интеграции'}}</li>
        <li>{{language === "EN" ? "awesome support" : 'потрясающая поддержка'}}</li>
        <li>{{language === "EN" ? "transparent terms of cooperation" : 'прозрачные условия сотрудничества'}}</li>
        <li>{{language === "EN" ? "developed finance department" : 'развитый финансовый отдел'}}</li>
      </ul>
      <button @click="goLink">{{language === 'EN' ? 'JOIN' : 'Присоединиться'}}</button>
    </div>
    <img class="background-img" src="../assets/images/background-img-information-section.jpg" alt="">
  </section>
</template>

<script>
import {defineComponent} from "vue";

export default defineComponent({
  props: {
    language: String
  },
  methods: {
    goLink() {
      window.location.href = 'https://bit.ly/3tCOkef';
    }
  }
})
</script>

<style scoped>

.information-section {
  position: relative;
  max-width: 1480px;
  margin: 0 auto;
  padding: 5.75rem 40px 0;
}

.information {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 60px;
}

.information > h1 {
  margin: 0;
  font-family: Inter, Arial, sans-serif;
  font-weight: 700;
  font-size: 76px;
  line-height: 76px;
  width: 40%;
}

@media (max-width: 1280px) {
  .information > h1 {
    width: 50%;
  }
}

@media (max-width: 1024px) {
  .information > h1 {
    width: 100%;
    text-align: center;
  }
}

@media (max-width: 768px) {
  .information > h1 {
    font-size: 52px;
    line-height: 52px;
    width: 100%;
    text-align: center;
  }
}

.information > ul {
  margin: 0;
  padding-left: 20px;
  width: 25%;
}

@media (max-width: 768px) {
  .information > ul {
    width: 100%;
  }
}

.information > ul > li {
  font-size: 20px;
  line-height: 28px;
  color: var(--mane-black);
}

.information > ul > li + li {
  margin-top: 10px;
}

.information > button {
  align-self: flex-start;
  font-family: Inter, Arial, sans-serif;
  font-size: 20px;
  line-height: 24px;
  color: #FDFDFD;
  border-radius: 0.6em;
  padding: 20px 32px;
  background-color: var(--mane-black);
  border: none;
  cursor: pointer;
  margin-bottom: 5.75rem;
}

@media (max-width: 768px) {
  .information > button {
    align-self: center;
  }
}

.background-img {
  position: absolute;
  bottom: 10%;
  right: 0;
  z-index: -1;
  height: auto;
  max-width: 70%;
}
</style>