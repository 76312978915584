<template>
  <div :style="cookie && 'display: none;'" class="modal-window" :class="{'modal-visible' : isModal}">
    <h3>{{language === 'EN' ? 'Cookies Policy' : 
        'Политика использования файлов cookie'}}
    </h3>
    <p>{{language === 'EN' 
        ? 'By using this site, you consent to the use of cookies to help us make it more convenient for you.' 
        : 'Используя данный сайт, вы даете согласие на использование файлов cookie, помогающих нам сделать его удобнее для вас.'}}
    </p>
    <button @click="onClick">{{language === 'EN' ? 'Accept' : 'Принять'}}</button>
  </div>
</template>

<script>
import {defineComponent} from "vue";

export default defineComponent( {
  props: {
    language: String,
    cookie: Boolean
  },
  data() {
    return {
      isModal: true
    }
  },
  methods: {
    onClick() {
      document.cookie = 'cookie=true'
      this.isModal = false;
    }
  }
})
</script>

<style scoped>
.modal-window {
  display: none;
  font-family: Inter, Arial, sans-serif;
  position: fixed;
  bottom: 20px;
  left: 20px;
  width: 300px;
  background-color: var(--mane-white);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.24);
  border-radius: 1rem;
  padding: 0.5rem;

  border: 1px solid var(--mane-black);
  flex-direction: column;
  align-items: center;
  gap: 8px;
}

.modal-visible {
  display: flex;
}

.modal-window > h3 {
  margin: 0;
  text-align: center;
  font-size: 20px;
  line-height: 22px;
}

.modal-window > p {
  margin: 0;
  font-size: 16px;
  line-height: 18px;
}

.modal-window > button {
  background-color: inherit;
  border: 1px solid var(--mane-black);
  font-size: 12px;
  padding: 6px 12px;
  border-radius: 8px;
  cursor: pointer;
  color: var(--mane-black);
}
</style>