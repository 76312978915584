<template>
  <section @mouseover="useAnimation" @mouseleave="deleteAnimation" class="use-section">
    <div class="content">
      <div class="content__list">
        <h2 class="section-name">{{language === 'EN' ? 'How to connect' : 'Как с нами работать:'}}</h2>
        <ol>
          <li :class="{'active':activeCardId === 1}" @click="setCards(1)">{{language === 'EN' ? 'Registration' : 'Регистрируйся'}}</li>
          <li :class="{'active':activeCardId === 2}" @click="setCards(2)">{{language === 'EN' ? 'Select BIN' : 'Выбирай бин'}}</li>
          <li :class="{'active':activeCardId === 3}" @click="setCards(3)">{{language === 'EN' ? 'Contact your personal manager' : 'Свяжись с менеджером'}}</li>
          <li :class="{'active':activeCardId === 4}" @click="setCards(4)">{{language === 'EN' ? 'Top up your account' : 'Пополни свой счет'}}</li>
          <li :class="{'active':activeCardId === 5}" @click="setCards(5)">{{language === 'EN' ? 'Use' : 'Зарабатывай'}}</li>
        </ol>
        <button class="main-button" :class="{'button-hidden':activeButton === undefined}" @click="goLink(activeButton)">{{
            language === 'EN' ?
                (activeButton) :
                (activeButton === 'registration' && 'регистрация' ||
                    activeButton === 'select' && 'Выбрать' ||
                    activeButton === 'contact' && 'Связаться') }}
        </button>
      </div>
      <div class="content_display">
        <div class="cards-wrapper">
          <div class="card card1">
            <div class="card__top">
              <div class="card__top__icon card__top__icon--red"></div>
              <div class="card__top__icon card__top__icon--yellow"></div>
              <div class="card__top__icon card__top__icon--green"></div>
            </div>
            <img class="img" :class="{'img-active': activeCardId === 1}" src="../assets/images/registration_img.svg" alt="">
            <img class="img" :class="{'img-active': activeCardId === 2}" src="../assets/images/bins_img.svg" alt="">
            <img class="img img-mobile" :class="{'img-active': activeCardId === 3}" src="../assets/images/manager_img.svg" alt="">
            <img class="img" :class="{'img-active': activeCardId === 4}" src="../assets/images/upAccount_img.svg" alt="">
            <img class="img" :class="{'img-active': activeCardId === 5}" src="../assets/images/use_img.svg" alt="">
            <div class="line">
              <div :class="{'updateLine': updateLine}" class="line-step"></div>
            </div>
          </div>
          <div class="card card2">
            <div class="card__top">
              <div class="card__top__icon card__top__icon--red"></div>
              <div class="card__top__icon card__top__icon--yellow"></div>
              <div class="card__top__icon card__top__icon--green"></div>
            </div>
          </div>
          <div class="card card3">
            <div class="card__top">
              <div class="card__top__icon card__top__icon--red"></div>
              <div class="card__top__icon card__top__icon--yellow"></div>
              <div class="card__top__icon card__top__icon--green"></div>
            </div>
          </div>
          <div class="card card4">
            <div class="card__top">
              <div class="card__top__icon card__top__icon--red"></div>
              <div class="card__top__icon card__top__icon--yellow"></div>
              <div class="card__top__icon card__top__icon--green"></div>
            </div>
          </div>
          <div class="card card5">
            <div class="card__top">
              <div class="card__top__icon card__top__icon--red"></div>
              <div class="card__top__icon card__top__icon--yellow"></div>
              <div class="card__top__icon card__top__icon--green"></div>
            </div>
          </div>
        </div>
        <p>
          {{language === 'EN' ? cardsList[activeCardId - 1].textEn : cardsList[activeCardId - 1].textRu }}
        </p>
      </div>
    </div>
  </section>
</template>

<script>
import {defineComponent} from "vue";

export default defineComponent ({
  props: {
    language: String
  },
  data() {
    return {
      endlessAnimation: null,
      activeButton: 'registration',
      activeCardId: 1,
      nextActiveCardID: 2,
      updateLine: false,
      cardsList: [
        {
          id: 1,
          name: 'registration',
          nameButton: 'registration',
          imgUrl: 'registration_img.svg',
          class: 'card1',
          imgClass: 'img--reg',
          textEn: 'Complete the registration on the website and provide all the necessary information.',
          textRu: 'Пройдите регистрацию на сайте и укажите все необходимые данные.'
        },
        {
          id: 2,
          name: 'selectBin',
          nameButton: 'select',
          imgUrl: 'bins_img.svg',
          class: 'card2',
          textEn: 'We have different bins: USD and EU; ' +
              'debit and credit; advertising and universal. ' +
              'Choose the one that suits you. ' +
              'If you need help and a complete list, contact our manager.',
          textRu: 'У нас есть различные бины: USD и EU; ' +
              'дебетовые и кредитные; рекламные и универсальные. ' +
              'Выберите тот, который подходит вам. ' +
              'Если вам нужна помощь или полный список, свяжитесь с нашим менеджером.'
        },
        {
          id: 3,
          name: 'contact',
          nameButton: 'contact',
          imgUrl: 'manager_img.svg',
          class: 'card3',
          imgClass: 'img--manager',
          textEn: 'As soon as you complete the registration and choose your bin, ' +
              'we recommend reaching out to your personal manager. ' +
              'They will answer all your questions and assist with the account verification.',
          textRu: 'Как только пройдете регистрацию и опредилитесь с бином, ' +
              'напишите нашему личному менеджеру. ' +
              'Он ответит на все вопросы и поможет верифицировать аккаунт.'
        },
        {
          id: 4,
          name: 'upAccount',
          nameButton: 'contact',
          imgUrl: 'upAccount_img.svg',
          class: 'card4',
          imgClass: 'img--up-account',
          textEn: 'Request the details for topping up your personal ' +
              'account from your personal manager. ' +
              'The funds will be credited immediately to the bin of your choice.',
          textRu: 'Запросите у личного менеджера реквизиты для пополнения личного счета. ' +
              'Средства буду зачислены на выбранный вами бин.'
        },
        {
          id: 5,
          name: 'use',
          imgUrl: 'use_img.svg',
          nameButton: 'registration',
          class: 'card5',
          imgClass: 'img--use',
          textEn: 'Create cards, link them to advertising accounts, and start earning.',
          textRu: 'Создавайте карты, привязывайте к рекламным аккаунтам. Зарабатывайте!'
        },
      ],
    }
  },
  methods: {
    setCards(id) {
      this.activeCardId = id;
      if (id === 5) {
        this.nextActiveCardID = 1;
      } else {
        this.nextActiveCardID = id + 1;
      }
      this.cardsList.forEach((card) => {
        if (card.id === this.activeCardId) {
          this.activeButton = card.nameButton
        }
      })
      this.deleteAnimation();
      setTimeout(() => {
        this.useAnimation()
      }, 100)

    },
    goLink(activeButton) {
      if (activeButton === 'registration') {
        window.location.href = 'https://bit.ly/3tCOkef';
      }
      if (activeButton === 'select') {
        window.location.href = 'https://bit.ly/402SFU0';
      }
      if (activeButton === 'contact') {
        window.location.href = 'https://t.me/ChocoKrist';
      }
    },
    useAnimation() {
      this.updateLine = true;
      if(this.endlessAnimation === null) {
        this.endlessAnimation = setInterval(() => {
          this.switchCard();
          this.updateLine = false;
          setTimeout(() => {
            this.updateLine = true;
          }, 20)
        }, 15000);
      }
    },
    switchCard() {
      this.activeCardId = this.nextActiveCardID;
      this.cardsList.forEach((card) => {
        if (card.id === this.activeCardId) {
          this.activeButton = card.nameButton;
        }
      })
      if (this.nextActiveCardID === 5) {
        this.nextActiveCardID = 1;
      } else {
        this.nextActiveCardID += 1;
      }

    },
    deleteAnimation() {
      setTimeout(() => {
        this.updateLine = false;
      }, 10)
      clearInterval(this.endlessAnimation);
      this.endlessAnimation = null;
    }
  }

})
</script>

<style scoped>
.use-section {
  background-color: var(--mane-black);
  color: #FDFDFD;
}

.content {
  max-width: 1480px;
  margin: 0 auto;
  padding: 5.75rem 40px;
  display: flex;
  gap: 5.75rem;
}

@media (max-width: 1280px) {
  .content {
    gap: 2rem;
  }
}

.content__list {
  flex-basis: 25%;
  display: flex;
  flex-direction: column;
  gap: 3rem;
}

.content__list > ol {
  margin: 0;
}

.content__list > ol > li {
  font-size: 20px;
  line-height: 28px;
  cursor: pointer;
  color: var(--gray);
}

.content__list .active {
  color: var(--mane-white);
}

.content__list > ol > li + li {
  margin-top: 10px;
}

.content__list > button {
  align-self: flex-start;
  font-family: Inter, Arial, sans-serif;
  font-size: 20px;
  line-height: 24px;
  color: var(--mane-black);
  border-radius: 0.6em;
  width: 200px;
  padding: 20px 0;
  background-color: #FDFDFD;
  border: none;
  cursor: pointer;
}

.section-name {
  margin: 0;
  font-family: Inter, Arial, sans-serif;
  font-size: 3rem;
  line-height: 3rem;
}

.content_display {
  display: flex;
  margin: 0 auto;
  flex-direction: column;
  gap: 2rem;
  padding: 1rem 0;
  width: calc(584px + 12rem);
}

@media (max-width: 1180px) {
  .content_display {
    width: calc(440px + 8rem);
    gap: 1rem;
  }
}

.content_display > p {
  height: 64px;
  letter-spacing: -0.5px;
  text-align: justify;
  margin: 0;
}

@media (max-width: 1180px) {
  .content_display > p {
    height: 64px;
  }
}

.cards-wrapper {
  height: 360px;
  position: relative;
}

.card {
  position: absolute;
  width: 586px;
  height: 347px;
  bottom: 0;
  border-radius: 20px;
  background-color: var(--mane-white);
  background-image: linear-gradient(to right, rgba(152, 152, 152, 1) 0%, rgba(231, 231, 231, 1) 30%);
  box-shadow: 5px 4px 9px rgba(0, 0, 0, 0.15);
  display: flex;
  flex-direction: column;
}

@media (max-width: 1180px) {
  .card {
    width: 440px;
  }
}

@media (max-width: 900px) {
  .card {
    display: none;
  }

  .cards-wrapper {
    display: none;
  }

  .content_display {
    justify-content: center;
    align-items: center;
  }

  .content_display > p {
    width: 60%;
  }
}

@media (max-width: 768px) {
  .content_display {
    display: none;
  }

  .content__list {
    flex-basis: 100%;
  }
}

.card__top {
  height: 40px;
  background-color: var(--mane-white);
  border-radius: 20px 20px 0 0;
}

.card__top__icon {
  display: inline-block;
  width: 18px;
  height: 18px;
  margin: 11px;
  border-radius: 100%;
}

.card__top__icon--red {
  background-color: #FE6262;
}

.card__top__icon--yellow {
  margin-left: 0;
  background-color: #FEDC62;
}

.card__top__icon--green {
  margin-left: 0;
  background-color: #41CA5D;
}

.main-button {
  text-transform: uppercase;
}

.card1 {
  z-index: 5;
  height: 347px;
  left: 0;
  overflow: hidden;
}

@media (max-width: 1180px) {
  .card1 {
    height: 300px;
  }
}

.card2 {
  z-index: 4;
  left: 3rem;
  height: calc(347px - 1rem);
  overflow: hidden;
}

@media (max-width: 1180px) {
  .card2 {
    height: calc(300px - 1rem);
    left: 2rem;
  }
}

.card3 {
  z-index: 3;
  left: 6rem;
  height: calc(347px - 2rem);
  overflow: hidden;
}

@media (max-width: 1180px) {
  .card3 {
    height: calc(300px - 2rem);
    left: 4rem;
  }
}

.card4 {
  z-index: 2;
  left: 9rem;
  height: calc(347px - 3rem);
  overflow: hidden;
}

@media (max-width: 1180px) {
  .card4 {
    height: calc(300px - 3rem);
    left: 6rem;
  }
}

.card5 {
  z-index: 1;
  left: 12rem;
  height: calc(347px - 4rem);
  overflow: hidden;
}

@media (max-width: 1180px) {
  .card5 {
    height: calc(300px - 4rem);
    left: 8rem;
  }
}

.line {
  position: absolute;
  bottom: 1rem;
  left: 2rem;
  right: 2rem;
  height: 4px;
  background-color: var(--mane-white);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 10px;
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.32);
}

.updateLine {
  position: absolute;
  height: 4px;
  background-color: var(--gray);
  border-radius: 10px;
  animation: lineFilling 15s linear;
}

@keyframes lineFilling {
  0% {
    width: 0;
  }

  100% {
    width: 100%;
  }
}

.img {
  display: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.img-active {
  display: block;
}

@media (max-width: 1180px) {
  .img {
    height: 60%;
  }
}

.img-mobile {
  bottom: 0;
  transform: translate(-50%, -30%);
}

@media (max-width: 1180px) {
  .img-mobile {
    height: 100%;
  }
}

.button-hidden {
  display: none;
}

</style>