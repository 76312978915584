<template>
  <section class="contact-us-section">
    <div class="content">
      <form class="form" @submit="onSubmit">
        <h2 class="section-title">{{language === 'EN' ? 'Contact Us' : 'Связаться с нами'}}</h2>
        <p class="section-information">{{language === 'EN' ? 'Fill out the form and we will contact you' : 'Заполните форму и мы свяжемся с вами'}}</p>
        <div class="form-elements">
          <div class="form-element">
            <label for="name-input">{{language === 'EN' ? 'Name*' : 'Имя*'}}</label>
            <input
                id="name-input"
                type="text"
                :placeholder="language === 'EN' ? 'Write you name here' : 'Напишите свое имя здесь'"
                :value="nameInput"
                @input="setNameInput"
                :class="{'input-error': nameInputError}"
            >
            <div :class="{'error-message': nameInputError}">{{nameInputError && (language === 'EN' ? 'Fill in the field' : 'Заполните поле')}}</div>
          </div>
          <div class="form-element">
            <label for="telegram-input">Telegram*</label>
            <input
                id="telegram-input"
                type="text"
                :placeholder="language === 'EN' ? 'Write you user ID here' : 'Напишите здесь свой id пользователя'"
                :value="telegramInput"
                @input="setTelegramInput"
                :class="{'input-error': telegramInputError}"
            >
            <div :class="{'error-message': telegramInputError}">{{telegramInputError && (language === 'EN' ? 'Fill in the field starting with @' : 'Заполните поле начиная с @')}}</div>
          </div>
          <div class="form-element">
            <label for="comment-input">{{language === 'EN' ? 'Comment' : 'Комментарий'}}</label>
            <input
                id="comment-input"
                type="text"
                :placeholder="language === 'EN' ? 'Write you comment here' : 'Напишите свой комментарий здесь'"
                :value="commentInput"
                @input="setCommentInput"
            >
          </div>
        </div>
        <button class="submit-button" type="submit">{{language === 'EN' ? 'CALL BACK' : 'ОТПРАВИТЬ'}}</button>
        <div class="message-container">
          <div class="error-block" :class="{'error-loading': errorLoading}">{{language === 'EN' ? 'An error occurred, try again later' : 'Произошла ошибка, попробуй позже'}}</div>
          <div class="success-block" :class="{'success-loading': successLoading}">{{language === 'EN' ? 'Message sent successfully' : 'Сообщение успешно отправлено'}}</div>
        </div>
      </form>
      <div class="img-wrapper">
        <img src="../assets/images/background-img-contactus-section.png" alt="">
      </div>
    </div>
  </section>
</template>

<script>
import {defineComponent} from "vue";

export default defineComponent ({
  props: {
    language: String
  },
  data() {
    return {
      nameInput: '',
      nameInputError: false,
      telegramInput: '',
      telegramInputError: false,
      commentInput: '',
      errorLoading: false,
      successLoading: false
    }
  },
  methods: {
    setNameInput(e) {
      this.nameInput = e.target.value;
    },
    setTelegramInput(e) {
      this.telegramInput = e.target.value;
    },
    setCommentInput(e) {
      this.commentInput = e.target.value;
    },
    async onSubmit(e) {
      e.preventDefault();
      this.errorLoading = false;
      this.successLoading = false;
      let firstChar = '';
      if (this.telegramInput) {
        firstChar = this.telegramInput.charAt(0);
      }
      if (this.nameInput && this.telegramInput && firstChar === '@') {
        if (this.commentInput === '') {
          this.commentInput = 'Без комментария'
        }
        try {
          const response = await fetch('https://chocopay.io/api/v1/form/create', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              name: this.nameInput,
              telegram: this.telegramInput,
              comment: this.commentInput
            })
          });
          if (response.ok) {
            this.errorLoading = false;
            this.successLoading = true;
          }
        } catch (e) {
          this.errorLoading = true;
        }
        this.nameInput = '';
        this.telegramInput = '';
        this.commentInput = '';
        this.nameInputError = false;
        this.telegramInputError = false;
      } else {
        this.errorLoading = false;
        this.nameInputError = this.nameInput === '';
        if (this.telegramInput === '' || firstChar !== '@') {
          this.telegramInputError = true
        }
      }
    }
  }
})
</script>

<style scoped>
.contact-us-section {
  font-family: Inter, Arial, sans-serif;
  background-color: var(--mane-black);
  color: var(--mane-white);
  padding: 5.75rem 0;
}

.content {
  max-width: 1480px;
  padding: 0 0 0 40px;
  margin: 0 auto;
  display: flex;
}

.form {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  width: 350px;
}

@media (max-width: 768px) {
  .content {
    width: calc(100% - 80px);
    padding: 0 40px;
  }

  .form {
    width: 100%;
  }
}

.form-elements {
  display: flex;
  flex-direction: column;
}

.section-title {
  margin: 0;
  font-weight: 700;
  font-size: 48px;
  line-height: 48px;
}

.section-information {
  font-family: OcraBecker, Arial, sans-serif;
  color: var(--gray);
  margin: 0;
}

.form-element {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.form-element > label {
  font-size: 20px;
}

.form-element > input {
  width: 300px;
  border: 1px solid var(--mane-white);
  border-radius: 12px;
  padding: 8px 16px;
  background-color: inherit;
  font-size: 1rem;
  color: var(--mane-white);
  outline: none;
}

.form-element > div {
  opacity: 0;
  height: 16px;
}

.form-element > .input-error {
  border: 1px solid red;
}

.form-element > .error-message {
  opacity: 1;
  font-size: 12px;
  line-height: 16px;
  color: red;
}

.submit-button {
  align-self: flex-start;
  font-family: Inter, Arial, sans-serif;
  font-size: 20px;
  line-height: 24px;
  color: var(--mane-black);
  border-radius: 0.6em;
  padding: 20px 32px;
  background-color: #FDFDFD;
  border: none;
  cursor: pointer;
}

.img-wrapper {
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 768px) {
  .img-wrapper {
    display: none;
  }
}

.img-wrapper > img {
  width: 50%;
}

.message-container {
  position: relative;
}

.error-block {
  position: absolute;
  opacity: 0;
}

.success-block {
  position: absolute;
  opacity: 0;
}

.error-loading {
  opacity: 1;
  font-size: 12px;
  line-height: 16px;
  color: red;
}

.success-loading {
  opacity: 1;
  font-size: 12px;
  line-height: 16px;
  color: var(--green);
}

</style>