<template>
  <MainPage />
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import MainPage from "@/pages/main-pages/MainPage.vue";

export default defineComponent({
  name: 'App',
  components: {
    MainPage
  }
});
</script>

<style>

@font-face {
  font-family: 'OcraBecker';
  src: url('@/assets/fonts/ocrabecker.otf');
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url("@/assets/fonts/Inter-Regular.ttf");
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url("@/assets/fonts/Inter-Bold.ttf");
  font-weight: 700;
  font-display: swap;
}

:root {
  font-size: 1em;
  --mane-black: #171717;
  --mane-white: #FDFDFD;
  --bins-purple: #758BFD;
  --gray: #4F4F4F;
  --light-gray: #989898;
  --green: #41CA5D;
  color: var(--mane-black);
}

body {
  margin: 0;
  box-sizing: border-box;
  font-family: OcraBecker, Arial, sans-serif;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

input[type='number'],
input[type="number"]:hover,
input[type="number"]:focus {
  appearance: none;
  -moz-appearance: textfield;
}

</style>
