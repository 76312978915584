import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppHeader = _resolveComponent("AppHeader")!
  const _component_information_section = _resolveComponent("information-section")!
  const _component_use_section = _resolveComponent("use-section")!
  const _component_bins_section = _resolveComponent("bins-section")!
  const _component_contact_us_section = _resolveComponent("contact-us-section")!
  const _component_app_footer = _resolveComponent("app-footer")!
  const _component_cookie_modal = _resolveComponent("cookie-modal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_AppHeader, {
      onChangeLanguage: _ctx.changeLanguage,
      language: _ctx.language
    }, null, 8, ["onChangeLanguage", "language"]),
    _createVNode(_component_information_section, {
      language: _ctx.language,
      id: "information"
    }, null, 8, ["language"]),
    _createVNode(_component_use_section, {
      id: "connect",
      language: _ctx.language
    }, null, 8, ["language"]),
    _createVNode(_component_bins_section, {
      language: _ctx.language,
      id: "bins"
    }, null, 8, ["language"]),
    _createVNode(_component_contact_us_section, {
      language: _ctx.language,
      id: "call-back"
    }, null, 8, ["language"]),
    _createVNode(_component_app_footer, { language: _ctx.language }, null, 8, ["language"]),
    _createVNode(_component_cookie_modal, {
      cookie: _ctx.cookie,
      language: _ctx.language
    }, null, 8, ["cookie", "language"])
  ], 64))
}