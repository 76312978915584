<template>
  <header class="header">
    <a href="/">
      <img class="header__logo" src="../assets/icons/logo-icon.svg" alt="logo" />
      <img class="header__logo--small" src="../assets/icons/favorite.svg" alt="logo" />
    </a>

    <div class="header__center-panel">
      <nav>
        <ul class="nav">
          <a href="/#information"><li>{{language === 'EN' ? 'Advantage': 'Преимущества' }}</li></a>
          <a href="/#connect"><li>{{language === 'EN' ? 'Connect': 'Подключиться' }}</li></a>
          <a href="/#bins"><li>{{language === 'EN' ? 'BINs': 'Бины' }}</li></a>
          <a href="/#call-back"><li>{{language === 'EN' ? 'Call back': 'Оставить заявку' }}</li></a>
        </ul>
      </nav>

      <div @mouseover="upHere = true" @mouseleave="upHere = false" class="switch-language-panel">
        <div class="switch-language-panel__select-language">
          <span>{{ language }}</span>
          <img src="../assets/icons/arrow-to-down-icon.svg" alt="">
        </div>
        <div v-show="upHere" class="switch-language-panel__popup-window">
          <div class="languages-wrapper">
            <span :class="{}" v-for="language in languagesList" @click="switchLanguage(language)" :key="language">{{language}}</span>
          </div>
        </div>
      </div>

    </div>

    <div class="header__authentication-panel">

      <a @mouseover="() => switchLoginIcon(true)"
         @mouseleave="() => switchLoginIcon(false)"
         href="https://chocopay.io/login" class="header__authentication-panel__navigation-link">
        <span>{{language === 'EN' ? 'login': 'войти' }}</span>
        <img :src="loginIconUrl" alt="">
      </a>

      <a @mouseover="() => switchRegistrationIcon(true)"
         @mouseleave="() => switchRegistrationIcon(false)"
         href="https://bit.ly/3tCOkef" class="header__authentication-panel__navigation-link">
        <span>{{language === 'EN' ? 'registration': 'регистрация' }}</span>
        <img :src="registerIconUrl" alt="">
      </a>

    </div>

  </header>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    language: String,
  },
  data() {
    return {
      buttonValue: 'EU',
      languagesList: [
        'English',
        'Russian'
      ],
      upHere: false,
      upLoginLink: false,
      registerLinkActive: false,
      registerIconUrl: require('@/assets/icons/registration-icon.svg'),
      registerIconDefaultUrl: require('@/assets/icons/registration-icon.svg'),
      registerIconActiveUrl: require('@/assets/icons/registration-active-icon.svg'),
      loginLinkActive: false,
      loginIconUrl: require('@/assets/icons/login-icon.svg'),
      loginIconDefaultUrl: require('@/assets/icons/login-icon.svg'),
      loginIconActiveUrl: require('@/assets/icons/login-active-icon.svg'),
    }
  },
  methods: {
    switchLanguage(language: string) {
      if (language === 'English') {
        document.cookie = `language = EN`
        this.$emit('changeLanguage', 'EN')
        this.upHere = false;
      } else {
        document.cookie = `language = RU`
        this.$emit('changeLanguage', 'RU')
        this.upHere = false;
      }
    },
    switchRegistrationIcon(state: boolean) {
      this.registerLinkActive = state;
        if (this.registerLinkActive) {
          this.registerIconUrl = this.registerIconActiveUrl
        } else {
          this.registerIconUrl = this.registerIconDefaultUrl
        }
    },
    switchLoginIcon(state:boolean) {
      this.loginLinkActive = state;
      if (this.loginLinkActive) {
        this.loginIconUrl = this.loginIconActiveUrl
      } else {
        this.loginIconUrl = this.loginIconDefaultUrl
      }
    }
  }
})
</script>

<style scoped>

.header {
  max-width: 1480px;
  margin: 60px auto 0;
  padding: 0 40px;
  display: flex;
  justify-content: space-between;
  gap: 28px;
  align-items: center;
  letter-spacing: -0.5px;
}

span {
  font-family: OcraBecker, Arial, sans-serif;
}

.header__logo {
  max-width: 214px;
}

@media (max-width: 1340px) {
  .header__logo {
    display: none;
  }
}

.header__logo--small {
  display: none;
}

@media (max-width: 1340px) {
  .header__logo--small {
    display: block;
  }
}

.header__center-panel {
 display: flex;
  gap: 28px;
  align-items: center;
}

.nav {
  list-style-type: none;
  margin: 0;
  padding: 0 0.75em;
  border: 1px solid var(--mane-black);
  border-radius: 1rem;
}

.nav > a {
  display: inline-block;
  padding: 0.75em;
  color: var(--mane-black);
  text-decoration: none;
  transition: color 0.1s ease;
}

@media (max-width: 768px) {
  .nav {
    display: none;
  }
}

.nav > a:hover {
  color: var(--bins-purple);
}

.switch-language-panel {
  position: relative;
  display: flex;
  border-radius: 1rem;
  padding: 0.75rem;
  background-color: var(--mane-white);
  color: var(--mane-black);
  border: 1px solid var(--mane-black);
}

.switch-language-panel__select-language {
  display: flex;
  gap: 0.5rem;
}

.switch-language-panel__popup-window {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
}

.languages-wrapper {
  margin-top: 50px;
  background-color: var(--mane-white);
  width: auto;
  display: flex;
  flex-direction: column;
  padding: 1rem;
  align-items: flex-start;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.24);
  border-radius: 1rem;
}

.languages-wrapper > span {
  color: var(--light-gray);
  padding: 0.5rem 0;
  cursor: pointer;
}

.languages-wrapper > span:hover {
  color: var(--bins-purple);
}

.switch-language-panel__popup-window > button {
  padding: 0.75rem;
  font-size: 1rem;
  background-color: inherit;
  border: none;
  font-family: OcraBecker, Arial, sans-serif;
  margin: 0 auto;
  width: 100%;
  border-radius: 1rem;
  cursor: pointer;
}

.header__authentication-panel {
  display: flex;
  gap: 2rem;
  align-items: center;
}

@media (max-width: 1130px) {
  .header__authentication-panel {
    flex-direction: column-reverse;
    gap: 0;
    align-items: flex-end;
  }
}

.header__authentication-panel__navigation-link {
  display: flex;
  align-items: center;
  color: var(--mane-black);
  text-decoration: none;
  padding: 0.75rem 0;
}

@media (max-width: 1130px) {
  .header__authentication-panel__navigation-link {
    padding: 0.25rem 0;
  }
}

.header__authentication-panel__navigation-link:hover {
  color: var(--bins-purple);
}

.header__authentication-panel__navigation-link > img {
  margin-left: 0.5rem;
}

</style>