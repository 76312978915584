<template>
  <footer class="footer">
    <div class="footer__left">
      <a href="/"><img src="../assets/icons/logo-icon.svg" alt=""></a>
      <nav>
        <ul class="nav">
          <a class="left-link" href="/#information"><li>{{language === 'EN' ? 'Advantage': 'Преимущество' }}</li></a>
          <a href="/#connect"><li>{{language === 'EN' ? 'Connect': 'Подключиться' }}</li></a>
          <a href="/#bins"><li>{{language === 'EN' ? 'BINs': 'Бины' }}</li></a>
          <a href="/#call-back"><li>{{language === 'EN' ? 'Call back': 'Оставить заявку' }}</li></a>
        </ul>
      </nav>
      <span>ChocoPay2023©</span>
    </div>
    <div class="footer__right">
      <p class="information">{{language === 'EN' ? 'Subscribe to the newsletter' : 'Подписаться на рассылку'}}</p>
      <button @click="goLink">{{language === 'EN' ? 'Subscribe' : 'Подписаться'}}</button>
      <p class="support">{{language === 'EN' ? 'Сustomer service ' : 'Обслуживание клиентов '}}<a href="https://t.me/ChocoKrist">@chocopay_support</a></p>
    </div>
  </footer>
</template>

<script>
import {defineComponent} from "vue";

export default defineComponent ({
  props: {
    language: String
  },
  methods: {
    goLink() {
      window.location.href = 'https://bit.ly/3tCOkef';
    }
  }
})
</script>

<style scoped>
.footer {
  margin: 2rem auto;
  max-width: 1480px;
  padding: 0 40px;
  display: flex;
  justify-content: space-between;
}

.footer__left {
  display: flex;
  flex-direction: column;
  gap: 1.1rem;
}

.footer__left > a > img {
  width: 128px;
}

.nav {
  list-style-type: none;
  margin: 0;
  border-radius: 1rem;
  padding: 0;
}


.nav > a {
  display: inline-block;
  padding: 0.75em;
  color: var(--mane-black);
  text-decoration: none;
  transition: color 0.1s ease;
  letter-spacing: -0.5px;
}

.nav > .left-link {
  padding-left: 0;
}

.nav > a:hover {
  color: var(--bins-purple);
}

.footer__left > span {
  font-family: Inter, Arial, sans-serif;
  font-size: 12px;
  line-height: 16px;
  color: var(--light-gray);
}

.footer__right {
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
  font-family: Inter, Arial, sans-serif;
}

.footer__right > button {
  font-family: Inter, Arial, sans-serif;
  font-size: 16px;
  line-height: 18px;
  color: #FDFDFD;
  border-radius: 0.6em;
  padding: 8px 16px;
  background-color: var(--mane-black);
  border: none;
  cursor: pointer;
}

.information {
  text-align: right;
  margin: 0;
  font-size: 20px;
  line-height: 24px;
}

.support {
  text-align: right;
  margin: 0;
  font-size: 16px;
  line-height: 18px;
}

.support > a {
  color: var(--bins-purple);
  text-decoration: none;
}

</style>