<template>
  <a href="https://t.me/ChocoKrist" class="bin-wrapper">
    <div class="bin-first-line">
      <div class="bin__id-container">
        <span class="id-title">BIN</span>
        <span class="id">{{binElement.id}}</span>
      </div>
      <div class="bin__services">
        <div class="service">
          <span>Google</span>
          <img :src="binElement.services.googleImgUrl" alt="">
          <span
              class="service-value"
              :class="{'service-value-gray': binElement.services.google < 25}"
          >
            {{binElement.services.google}}%
          </span>
        </div>
        <div class="service">
          <span>Facebook</span>
          <img :src="binElement.services.facebookImgUrl" alt="">
          <span
              class="service-value"
              :class="{'service-value-gray': binElement.services.facebook < 25}"
          >
            {{binElement.services.facebook}}%
          </span>
        </div>
        <div class="service">
          <span>Service</span>
          <img :src="binElement.services.serviceImgUrl" alt="">
          <span
              class="service-value"
              :class="{'service-value-gray': binElement.services.service < 25}"
          >
            {{binElement.services.service}}%
          </span>
        </div>
      </div>
    </div>
    <div class="bin-second-line">
      <div class="bin-second-line__column">
        <span class="title">Geo</span>
        <span class="value">{{binElement.geo}}</span>
      </div>
      <div class="bin-second-line__column">
        <span class="title">Currency</span>
        <span class="value">{{binElement.currency}}</span>
      </div>
    </div>
  </a>
</template>

<script>
import {defineComponent} from "vue";

export default defineComponent ({
  props: {
    binElement: {
      id: Number,
      services: {
        googleIconUrl: String,
        facebookIconUrl: String,
        serviceIconUrl: String,
      },
      geo: String,
      currency: String
    }
  }
})
</script>

<style scoped>
.bin-wrapper {
  width: calc(392px - 58px);
  padding: 28px;
  border: 1px solid var(--mane-black);
  border-radius: 0.75rem;
  display: flex;
  flex-direction: column;
  gap: 28px;
  height: max-content;
  color: inherit;
  text-decoration: none;
}

@media (max-width: 768px) {
  .bin-wrapper {
    width: 100%;
    padding: 0.5rem;
  }
}

.bin-first-line {
  display: flex;
  justify-content: space-between;
  padding-right: 13px;
}

@media (max-width: 768px) {
  .bin-first-line {
    padding-right: 0;
  }
}

.bin__id-container {
  display: flex;
  flex-direction: column;
}

.id-title {
  font-size: 48px;
  line-height: 48px;
  font-weight: 700;
  font-family: Inter, Arial, sans-serif;
}

.id {
  font-family: OcraBecker, Arial, sans-serif;
}

.bin__services {
  display: flex;
  gap: 12px;
  font-size: 1rem;
}

.bin__services span {
  font-family: Inter, Arial, sans-serif;
}

.bin__services .service-value {
  font-size: 12px;
  line-height: 16px;
  color: var(--bins-purple);
}

.bin__services .service-value-gray {
  color: var(--light-gray);
}

.service {
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: center;
}

.bin-second-line {
  display: flex;
  gap: 2rem;
}

.bin-second-line__column {
  display: flex;
  flex-direction: column;
}

.bin-second-line__column > .title {
  font-family: Inter, Arial, sans-serif;
  font-size: 28px;
  line-height: 32px;
  font-weight: 600;
}
</style>