<template>
  <section>
    <div class="content">
      <div class="content__header">
        <h2 :class="{'russian-title':language === 'RU'}" class="section-title">{{language === 'EN' ? 'Our BINs' : 'Наши Бины'}}</h2>
        <p>
          {{language === 'EN' ? "Choose a card for your purposes. Some of the bins are already integrated and available in your personal account. If you haven't found any bin, contact your manager."
            : 'Выберите карту для своих целей. Некоторые карты уже интегрированы и доступны в личном кабинете. Если вы не нашли ни одной карты, свяжитесь с вашим менеджером.'}}

        </p>
      </div>
      <div class="panel">
        <div class="panel-header">
          <div>
            <button
                class="panel-header__button"
                :class="{'active-button' :activeAdvertisingButton}"
                @click="useFilter('advertising')"
            >
              {{language === 'EN' ? 'advertising' : 'рекламные'}}
            </button>
            <button
                class="panel-header__button"
                :class="{'active-button' :activeUniversalButton}"
                @click="useFilter('universal')"
            >
              {{language === 'EN' ? 'universal' : 'универсальные'}}
            </button>
            <button
                class="panel-header__button"
                :class="{'active-button' :activeUsdButton}"
                @click="useFilter('USD')"
            >
              USD
            </button>
            <button
                class="panel-header__button"
                :class="{'active-button' :activeEurButton}"
                @click="useFilter('EUR')"
            >
              EUR
            </button>
            <button @click="resetFilters" class="panel-header__button last-button">{{language === 'EN' ? 'reset' : 'сбросить'}}</button>
          </div>

          <div class="panel-header__search">
            <img @click="searchBin" src="../assets/icons/search-icon.svg" alt="">
            <input id="search-input" @keyup.enter="searchBin" :value="searchInput" @input="inputSearchBin" type="number" :placeholder="language === 'EN' ? 'search by bin' : 'поиск по бину'">
          </div>
        </div>
        <div class="panel__bins-elements">
          <bin-element v-for="bin in binsListFilter" :key="bin.keyId" :binElement="bin" />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import {defineComponent} from "vue";
import BinElement from "@/components/BinElement.vue";

export default defineComponent ({
  props: {
    language: String
  },
  components: {BinElement},
  data() {
    return {
      binsList: [
        {
          id: 450350,
          keyId: 0,
          services: {
            google: 23,
            googleImgUrl: 'bin-0.svg',
            facebook: 10,
            facebookImgUrl: 'bin-0.svg',
            service: 80,
            serviceImgUrl: 'bin-75.svg',
          },
          geo: 'US',
          currency: 'USD',
          advertising: false,
          universal: true
        },
        {
          id: 428823,
          keyId: 1,
          services: {
            google: 8,
            googleImgUrl: 'bin-0.svg',
            facebook: 15,
            facebookImgUrl: 'bin-0.svg',
            service: 71,
            serviceImgUrl: 'bin-50.svg',
          },
          geo: 'US',
          currency: 'USD',
          advertising: false,
          universal: true
        },
        {
          id: 532746,
          keyId: 2,
          services: {
            google: 42,
            googleImgUrl: 'bin-25.svg',
            facebook: 0,
            facebookImgUrl: 'bin-0.svg',
            service: 98,
            serviceImgUrl: 'bin-75.svg',
          },
          geo: 'US',
          currency: 'USD',
          advertising: false,
          universal: true
        },
        {
          id: 514802,
          keyId: 3,
          services: {
            google: 16,
            googleImgUrl: 'bin-0.svg',
            facebook: 0,
            facebookImgUrl: 'bin-0.svg',
            service: 70,
            serviceImgUrl: 'bin-50.svg',
          },
          geo: 'US',
          currency: 'USD',
          advertising: false,
          universal: true
        },
        {
          id: 474362,
          keyId: 4,
          services: {
            google: 90,
            googleImgUrl: 'bin-75.svg',
            facebook: 92,
            facebookImgUrl: 'bin-75.svg',
            service: 15,
            serviceImgUrl: 'bin-0.svg',
          },
          geo: 'EU (Estonia)',
          currency: 'USD',
          advertising: true,
          universal: false
        },
        {
          id: 474362,
          keyId: 5,
          services: {
            google: 91,
            googleImgUrl: 'bin-75.svg',
            facebook: 80,
            facebookImgUrl: 'bin-75.svg',
            service: 92,
            serviceImgUrl: 'bin-75.svg',
          },
          geo: 'EU (Estonia)',
          currency: 'EUR',
          advertising: true,
          universal: true
        },
        {
          id: 428813,
          keyId: 6,
          services: {
            google: 76,
            googleImgUrl: 'bin-75.svg',
            facebook: 94,
            facebookImgUrl: 'bin-75.svg',
            service: 25,
            serviceImgUrl: 'bin-25.svg',
          },
          geo: 'US',
          currency: 'USD',
          advertising: true,
          universal: false
        },
        {
          id: 555782,
          keyId: 7,
          services: {
            google: 90,
            googleImgUrl: 'bin-75.svg',
            facebook: 72,
            facebookImgUrl: 'bin-50.svg',
            service: 25,
            serviceImgUrl: 'bin-25.svg',
          },
          geo: 'US',
          currency: 'USD',
          advertising: true,
          universal: false
        },
        {
          id: 540542,
          keyId: 8,
          services: {
            google: 87,
            googleImgUrl: 'bin-75.svg',
            facebook: 81,
            facebookImgUrl: 'bin-75.svg',
            service: 50,
            serviceImgUrl: 'bin-50.svg',
          },
          geo: 'Hong Kong',
          currency: 'USD',
          advertising: true,
          universal: false
        },
        {
          id: 556735,
          keyId: 9,
          services: {
            google: 90,
            googleImgUrl: 'bin-75.svg',
            facebook: 88,
            facebookImgUrl: 'bin-75.svg',
            service: 50,
            serviceImgUrl: 'bin-50.svg',
          },
          geo: 'US',
          currency: 'USD',
          advertising: true,
          universal: false
        },
        {
          id: 485953,
          keyId: 11,
          services: {
            google: 44,
            googleImgUrl: 'bin-25.svg',
            facebook: 59,
            facebookImgUrl: 'bin-50.svg',
            service: 95,
            serviceImgUrl: 'bin-75.svg',
          },
          geo: 'US',
          currency: 'USD',
          advertising: false,
          universal: true
        }
      ],
      binsListFilter: [
        {
          id: 450350,
          keyId: 0,
          services: {
            google: 23,
            googleImgUrl: 'bin-0.svg',
            facebook: 10,
            facebookImgUrl: 'bin-0.svg',
            service: 80,
            serviceImgUrl: 'bin-75.svg',
          },
          geo: 'US',
          currency: 'USD',
          advertising: false,
          universal: true
        },
        {
          id: 428823,
          keyId: 1,
          services: {
            google: 8,
            googleImgUrl: 'bin-0.svg',
            facebook: 15,
            facebookImgUrl: 'bin-0.svg',
            service: 71,
            serviceImgUrl: 'bin-50.svg',
          },
          geo: 'US',
          currency: 'USD',
          advertising: false,
          universal: true
        },
        {
          id: 532746,
          keyId: 2,
          services: {
            google: 42,
            googleImgUrl: 'bin-25.svg',
            facebook: 0,
            facebookImgUrl: 'bin-0.svg',
            service: 98,
            serviceImgUrl: 'bin-75.svg',
          },
          geo: 'US',
          currency: 'USD',
          advertising: false,
          universal: true
        },
        {
          id: 514802,
          keyId: 3,
          services: {
            google: 16,
            googleImgUrl: 'bin-0.svg',
            facebook: 0,
            facebookImgUrl: 'bin-0.svg',
            service: 70,
            serviceImgUrl: 'bin-50.svg',
          },
          geo: 'US',
          currency: 'USD',
          advertising: false,
          universal: true
        },
        {
          id: 474362,
          keyId: 4,
          services: {
            google: 90,
            googleImgUrl: 'bin-75.svg',
            facebook: 92,
            facebookImgUrl: 'bin-75.svg',
            service: 15,
            serviceImgUrl: 'bin-0.svg',
          },
          geo: 'EU (Estonia)',
          currency: 'USD',
          advertising: true,
          universal: false
        },
        {
          id: 474362,
          keyId: 5,
          services: {
            google: 91,
            googleImgUrl: 'bin-75.svg',
            facebook: 80,
            facebookImgUrl: 'bin-75.svg',
            service: 92,
            serviceImgUrl: 'bin-75.svg',
          },
          geo: 'EU (Estonia)',
          currency: 'EUR',
          advertising: true,
          universal: true
        },
        {
          id: 428813,
          keyId: 6,
          services: {
            google: 76,
            googleImgUrl: 'bin-75.svg',
            facebook: 94,
            facebookImgUrl: 'bin-75.svg',
            service: 25,
            serviceImgUrl: 'bin-25.svg',
          },
          geo: 'US',
          currency: 'USD',
          advertising: true,
          universal: false
        },
        {
          id: 555782,
          keyId: 7,
          services: {
            google: 90,
            googleImgUrl: 'bin-75.svg',
            facebook: 72,
            facebookImgUrl: 'bin-50.svg',
            service: 25,
            serviceImgUrl: 'bin-25.svg',
          },
          geo: 'US',
          currency: 'USD',
          advertising: true,
          universal: false
        },
        {
          id: 540542,
          keyId: 8,
          services: {
            google: 87,
            googleImgUrl: 'bin-75.svg',
            facebook: 81,
            facebookImgUrl: 'bin-75.svg',
            service: 50,
            serviceImgUrl: 'bin-50.svg',
          },
          geo: 'Hong Kong',
          currency: 'USD',
          advertising: true,
          universal: false
        },
        {
          id: 556735,
          keyId: 9,
          services: {
            google: 90,
            googleImgUrl: 'bin-75.svg',
            facebook: 88,
            facebookImgUrl: 'bin-75.svg',
            service: 50,
            serviceImgUrl: 'bin-50.svg',
          },
          geo: 'US',
          currency: 'USD',
          advertising: true,
          universal: false
        },
        {
          id: 485953,
          keyId: 11,
          services: {
            google: 44,
            googleImgUrl: 'bin-25.svg',
            facebook: 59,
            facebookImgUrl: 'bin-50.svg',
            service: 95,
            serviceImgUrl: 'bin-75.svg',
          },
          geo: 'US',
          currency: 'USD',
          advertising: false,
          universal: true
        }
      ],
      activeAdvertisingButton: false,
      activeUniversalButton: false,
      activeUsdButton: false,
      activeEurButton: false,
      filtersList: [],
      searchInput: ''
    }
  },
  methods: {
    useFilter(filterName) {
      this.switchButtonColor(filterName)
      if (this.filtersList.includes(filterName)) {
        this.filtersList.splice(this.filtersList.indexOf(filterName), 1);
        this.filterList();
      } else {
        this.filtersList.push(filterName);

        if (filterName === 'advertising') {
          if (this.filtersList.includes('universal')) {
            this.filtersList.splice(this.filtersList.indexOf('universal'), 1);
          }
        }

        if (filterName === 'universal') {
          if (this.filtersList.includes('advertising')) {
            this.filtersList.splice(this.filtersList.indexOf('advertising'), 1);
          }
        }

        if (filterName === 'USD') {
          if (this.filtersList.includes('EUR')) {
            this.filtersList.splice(this.filtersList.indexOf('EUR'), 1);
          }
        }
        if (filterName === 'EUR') {
          if (this.filtersList.includes('USD')) {
            this.filtersList.splice(this.filtersList.indexOf('USD'), 1);
          }
        }
        this.filterList();
      }
    },
    filterList() {
      this.searchInput = '';
      if (this.filtersList.length === 0) {
        this.binsListFilter = this.binsList;
        return;
      }
      this.binsListFilter = [];
      if (this.filtersList.includes('advertising')) {
        this.binsList.forEach((bin) => {
          if (bin.advertising === true) {
            this.binsListFilter.push(bin)
          }
        })
      }
      if (this.filtersList.includes('universal')) {
        this.binsList.forEach((bin) => {
          if (bin.universal === true) {
            this.binsListFilter.push(bin)
          }
        })
      }
      if (this.filtersList.includes('USD')) {
        if (this.binsListFilter.length !== 0) {
          const newList = []
          this.binsListFilter.forEach((bin) => {
            if (bin.currency === 'USD') {
              newList.push(bin)
            }
          })
          this.binsListFilter = newList;
        } else {
          this.binsList.forEach((bin) => {
            if (bin.currency === 'USD') {
              this.binsListFilter.push(bin)
            }
          })
        }
        }
        if (this.filtersList.includes('EUR')) {
          if (this.binsListFilter.length !== 0) {
            const newList = []
            this.binsListFilter.forEach((bin) => {
              if (bin.currency === 'EUR') {
                newList.push(bin)
              }
            })
            this.binsListFilter = newList;
          } else {
            this.binsList.forEach((bin) => {
              if (bin.currency === 'EUR') {
                this.binsListFilter.push(bin)
              }
            })
          }

      }
    },
    switchButtonColor(buttonName) {
      if (buttonName === 'advertising') {
        this.activeAdvertisingButton = !this.activeAdvertisingButton;
        if (this.activeUniversalButton) {
          this.activeUniversalButton = false;
        }
      }
      if (buttonName === 'universal') {
        this.activeUniversalButton = !this.activeUniversalButton;
        if (this.activeAdvertisingButton) {
          this.activeAdvertisingButton = false
        }
      }
      if (buttonName === 'USD') {
        this.activeUsdButton = !this.activeUsdButton
        if (this.activeEurButton) {
          this.activeEurButton = false
        }
      }
      if (buttonName === 'EUR') {
        this.activeEurButton = !this.activeEurButton;
        if (this.activeUsdButton) {
          this.activeUsdButton = false;
        }
      }
    },
    resetFilters() {
      this.binsListFilter = this.binsList;
      this.activeAdvertisingButton = false;
      this.activeUniversalButton = false;
      this.activeUsdButton = false;
      this.activeEurButton = false;
      this.filtersList = [];
      this.searchInput = '';
    },
    searchBin() {
      if (!this.searchInput) {
        this.filterList();
        return;
      }

      const newList = [];
      this.binsListFilter.forEach(bin => {
        if (bin.id === Number(this.searchInput)) {
          newList.push(bin)
        }
      })
      this.binsListFilter = newList;
      this.activeAdvertisingButton = false;
      this.activeUniversalButton = false;
      this.activeUsdButton = false;
      this.activeEurButton = false;
      this.filtersList = [];
    },
    inputSearchBin(e) {
      this.searchInput = e.target.value;
    }
  }
})
</script>

<style scoped>
.content {
  max-width: 1480px;
  margin: 0 auto;
  padding: 10rem 40px;
  display: flex;
  flex-direction: column;
  gap: 5.75rem;
  font-family: Inter, Arial, sans-serif;
}

.content__header {
  max-width: 1400px;
  display: flex;
  align-items: center;
  gap: 2rem;
}

@media (max-width: 992px) {
  .content__header {
    flex-direction: column;
    align-items: flex-start;
  }

  .content__header > h2 {
    font-size: 84px;
    line-height: 84px;
    text-align: center;
    width: 100%;
  }
}

.content__header > p {
  margin-left: auto;
  flex-basis: 280px;
  font-family: OcraBecker, Arial, sans-serif;
  text-align: justify;
  letter-spacing: -0.5px;
}

.section-title {
  flex-shrink: 1;
  margin: 0;
  font-weight: 700;
  font-size: 190px;
  line-height: 180px;
  text-transform: uppercase;
  letter-spacing: -1px;
  display: inline-block;
}

@media (max-width: 1420px) {
  .section-title {
    width: 50%;
  }

  .content__header > p {
    align-self: flex-start;
  }
}

@media (max-width: 992px) {
  .content__header > p {
    flex-basis: 0;
  }
}

.russian-title {
  font-size: 150px;
  line-height: 150px;
}

.panel {
  max-width: 1264px;
  width: 100%;
  margin-right: auto;
  display: flex;
  flex-direction: column;
}

.panel-header {
  width: 1264px;
  display: flex;
  justify-content: space-between;
}

@media (max-width: 1350px) {
  .panel-header {
    max-width: 1264px;
    width: auto;
  }
}

@media (max-width: 700px) {
  .panel-header {
    flex-wrap: wrap;
  }
}

.panel-header__button{
  margin-top: 0.5rem;
  background-color: inherit;
  border: 1px solid var(--mane-black);
  border-radius: 0.75rem;
  padding: 0.5rem 1.25rem;
  cursor: pointer;
  color: var(--mane-black);
}

.active-button {
  background-color: var(--mane-black);
  color: var(--mane-white);
}

.panel-header__button + .panel-header__button {
  margin-left: 20px;
}

.panel-header .last-button {
  border: none;
  padding: 0.5rem 0.25rem;
}

.panel-header__search {
  margin-top: 0.5rem;
  border: 1px solid var(--mane-black);
  display: flex;
  align-items: center;
  padding: 0.5rem 1.25rem;
  border-radius: 0.75rem;
  width: 280px;
  gap: 0.75rem;
}

@media (max-width: 700px) {
  .panel-header__search {
    margin-left: 0;
  }
}

.panel-header__search > img {
  cursor: pointer;
}

.panel-header__search > input {
  font-size: 1rem;
  width: 100%;
  border: none;
  line-height: 18px;
  outline: none;
}

.panel__bins-elements {
  margin-top: 2rem;
  min-height: 483px;
  display: flex;
  gap: 44px;
  flex-wrap: wrap;
}

</style>